import React from 'react';
import Container from 'react-bootstrap/Container';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Seo from '../components/Seo';
import BrandGrid from '../components/BrandGrid/BrandGrid';
import SupportForm from '../components/SupportForm/SupportForm';

const Support = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_CAPTCHA_SITE_ID}
      container={{
        element: 're-captcha-container',
        parameters: {
          badge: 'inline',
        },
      }}
    >
      <Seo
        title="Support Procedures &amp; Policies · Medical Web Experts"
        description="Access information about Medical Web Experts’ support services, including contact information and company support policies and procedures."
        canonical="/support/"
        schema={`
        {
          "@context": "http://schema.org/",
          "@type": "http://schema.org/ProfessionalService",
          "additionaltype": ["http://www.productontology.org/id/Web_design"],
          "name": "Medical Web Experts",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "5950 Sherry Ln, Ste 405A, Dallas, TX 75225, United States",
            "addressLocality": "Dallas",
            "addressRegion": "TX",
            "postalCode": "75225"
          },
          "image": "https://www.medicalwebexperts.com/images/mwe.png",
          "email": "info@medicalwebexperts.com",
          "telePhone": "+1-866-932-9944",
          "url": "https://www.medicalwebexperts.com/",
          "openingHours": "Mo,Tu,We,Th,Fr 09:00-17:00",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              "opens": "09:00",
              "closes": "17:00"
            }
          ],
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": "32.7816412",
            "longitude": "-96.7939741"
          },
          "priceRange": "$$",
          "sameAs": [
            "https://www.linkedin.com/company/medicalwebexperts",
            "https://www.youtube.com/user/MedicalWebExperts",
            "https://twitter.com/MedWebExperts/",
            "https://www.facebook.com/medical.website.design"
          ]
        }
        `}
      />
      <BrandGrid />

      {/* PAGE HEADER */}
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg={10} xl={8}>
            <header className="d-flex flex-column justify-content-center align-items-center text-center">
              <h1 className="no-misc text-muted mb-1 fw-light page-header mb-1">
                Support
              </h1>
              <h2 className="h1 h1--center">
                Need customer Assistance? We&apos;re here to Help!
              </h2>
            </header>
          </Col>
        </Row>
      </Container>

      <Container className="my-4">
        <Row className="justify-content-center mt-5">
          <Col lg={8}>
            <h3 className="mt-0 mb-3">
              I Have a Support Request. What Should I Do?
            </h3>
            <p>
              <strong>If you have an MWE Service Desk account:</strong> Use the
              MWE Service Desk to submit a support ticket. Your account manager
              will have provided you with a unique link to access Service Desk.
            </p>
            <p>
              <strong>If you do not have an MWE Service Desk account:</strong>{' '}
              Not all of our customers have been provided an MWE Service Desk
              account. If you do not have one, please call or email your account
              manager with your request instead. If you are contacting your
              account manager outside of business hours (9am-5pm ET
              Monday-Friday), he or she will be in touch with you the following
              business day. If this support request is an emergency, please
              follow these emergency support procedures.
            </p>
            <p>
              If you’re not sure who your account manager is, please complete
              the <a href="#support-form">support request form</a> below, or
              call <strong>(866) 932-9944</strong>.
            </p>
            <hr className="my-6 no-border" />
            <h2 className="h1 text-center h1--center mb-4">
              Support Eligibility
            </h2>
            <p>
              Technical support is available to all clients with any of the
              following packages for any website, hosted software, custom
              product, or custom software development project:
            </p>
            <ul className="ui-checklist d-flex my-5">
              <li className="ui-checklist-item">MWE Basic Hosting</li>
              <li className="ui-checklist-item">MWE Enterprise Hosting</li>
              <li className="ui-checklist-item">MWE Custom Hosting</li>
              <li className="ui-checklist-item">
                MWE Maintenance & Device Compatibility Packages
              </li>
            </ul>
            <p>
              When contacting Medical Web Experts for support, please provide a
              detailed explanation of the problem. This includes URLs on which
              you&apos;re seeing the issue, screenshots, and steps that must be
              followed by our team to replicate the issue. In many cases, a
              video recording may be the most effective way to communicate the
              problem. Failure to provide detailed information might prevent the
              Medical Web Experts&apos; support team from being able to take
              action on your issue in a timely manner.
            </p>
            <hr className="my-6 no-border" />
            <h2 className="h1 text-center h1--center mb-4">
              Problem Response Time
            </h2>
            <p>
              Please refer to our{' '}
              <a href="/service-level-agreement/">
                Service Level Agreement (SLA)
              </a>{' '}
              for information on response times.
            </p>
            <p>
              Due to the complex nature of software and website development and
              operating environments, Medical Web Experts cannot guarantee that
              problems can be resolved within any set period. We can, however,
              guarantee a response time.
            </p>
            <p>
              Please take into account that Medical Web Experts’ response time
              may be affected if the client fails to provide requested
              information and/or remote access to the client’s relevant
              network(s) or system(s), when applicable. We make our best effort
              to resolve problems as expeditiously as possible. In some
              instances, Medical Web Experts relies on 3rd party hosting
              environments and software, which may limit or delay response times
              or our ability to resolve issues in these environments.
            </p>
            <hr className="my-6 no-border" />
            <h2 className="h1 text-center h1--center mb-4">
              Critical Emergencies
            </h2>
            <p>
              A “critical emergency” means that an issue is rendering your app,
              portal, or website unusable. If you are experiencing a critical
              emergency, please fill out the Support Request Form below and{' '}
              <strong>
                check the box for <u>“This is an emergency”</u>
              </strong>
              . We respond to emergencies sent via this form even outside of
              business hours.{' '}
            </p>
            <p>
              Alternatively, during business hours (9am-5pm ET Monday-Friday),
              you may call your account manager.
            </p>
            <hr className="my-6" id="support-form" />
            <h2 className="h1 text-center h1--center mb-4">
              Support Request Form
            </h2>
            <p className="text-center">
              <strong>
                DO NOT INCLUDE PATIENT INFORMATION / PROTECTED HEALTH
                INFORMATION (PHI) IN THIS FORM.
              </strong>
            </p>
            <div className="mt-5">
              <SupportForm />
            </div>
          </Col>
        </Row>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default Support;
