import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Field, ErrorMessage } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { object, string } from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const intialValues = {
  websiteUrl: '',
  fullName: '',
  phone: '',
  email: '',
  problem: '',
  isEmergency: false,
};

const contactUsSchema = object().shape({
  websiteUrl: string().url().required().label('URL of website, portal or app'),
  fullName: string().required().label('Your name'),
  phone: string().required().label('Your direct phone number'),
  email: string().email().required().label('Your email address'),
  problem: string().required().label('Description of problem'),
});

const SupportForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFormSubmit = async (values, { setStatus }) => {
    try {
      const captchaToken = await executeRecaptcha('support');
      const response = await fetch('/.netlify/functions/support', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...values, captchaToken }),
      });

      if (!response.ok) {
        throw new Error();
      }

      navigate('/thank-you/');
    } catch (err) {
      setStatus(
        'We apologize, an error occurred while submitting your form. If this problem persists, please contact us at webmaster.',
      );
    }
  };

  return (
    <Formik
      initialValues={intialValues}
      onSubmit={handleFormSubmit}
      validationSchema={contactUsSchema}
    >
      {({ handleSubmit, isSubmitting, errors, touched, status }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate>
            <Row>
              <Form.Group
                as={Col}
                md="6"
                controlId="controlWebsiteUrl"
                className="mb-3"
              >
                <Form.Label>
                  URL of website, portal, or app experiencing problems*
                </Form.Label>
                <Field
                  as={Form.Control}
                  type="text"
                  name="websiteUrl"
                  size="lg"
                  isInvalid={errors.websiteUrl && touched.websiteUrl}
                />
                <ErrorMessage
                  name="websiteUrl"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                controlId="controlFullName"
                className="mb-3"
              >
                <Form.Label>Your Name*</Form.Label>
                <Field
                  as={Form.Control}
                  type="text"
                  name="fullName"
                  size="lg"
                  isInvalid={errors.fullName && touched.fullName}
                />
                <ErrorMessage
                  name="fullName"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                md="6"
                controlId="controlPhone"
                className="mb-3"
              >
                <Form.Label>Your direct phone number*</Form.Label>
                <Field
                  as={Form.Control}
                  type="tel"
                  name="phone"
                  size="lg"
                  isInvalid={errors.phone && touched.phone}
                />
                <ErrorMessage
                  name="phone"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                controlId="controlEmail"
                className="mb-3"
              >
                <Form.Label>Your email address*</Form.Label>
                <Field
                  as={Form.Control}
                  type="email"
                  name="email"
                  size="lg"
                  isInvalid={errors.email && touched.email}
                />
                <ErrorMessage
                  name="email"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} controlId="controlProblem" className="mb-3">
                <Form.Label>Description of problem*</Form.Label>
                <Field name="problem">
                  {({ field }) => {
                    return (
                      <Form.Control
                        as="textarea"
                        {...field}
                        style={{ height: '125px' }}
                        isInvalid={errors.problem && touched.problem}
                      />
                    );
                  }}
                </Field>
                <ErrorMessage
                  name="problem"
                  component={Form.Control.Feedback}
                  type="invalid"
                />
              </Form.Group>
            </Row>

            <Row>
              <Col>
                <Field
                  as={Form.Check}
                  type="checkbox"
                  name="isEmergency"
                  label="This is an emergency (ONLY CHECK THIS BOX IF THIS IS A TRUE CRITICAL EMERGENCY)"
                />
              </Col>
            </Row>

            <Row className="justify-content-between mt-4">
              <Col md="auto">
                <div id="re-captcha-container" />
              </Col>
              <Col md="auto" className="mt-4 mt-md-0">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="w-100"
                >
                  {isSubmitting ? 'Sending...' : 'Send'}
                </Button>
              </Col>
            </Row>

            {status && (
              <Alert
                variant="danger"
                className="mt-4 p-4 text-white"
                transition={false}
              >
                {status}
              </Alert>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SupportForm;
